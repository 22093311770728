
<template>
<form>
    <fieldset data-quantity="">
     <button type="button" title="Down" class="sub" v-on:click="counterChange(-1)"/>
     <input type="number" name="quantity" v-model="quantity" pattern="[0-9]+"/>
     <button type="button" title="Up" class="add" v-on:click="counterChange(1)" /> 
    </fieldset>

  </form>
  <div class="skipcaption"> Title row: </div>

</template>

<script>

export default {
    name:'incrementSpinner',
    data() {
        return {
            quantity: 1
        }
    },
    emits:[ 'updateSkiprows'],
    methods: {
        counterChange: function(amt) {
            if (this.quantity == 1 && amt == -1 ) {
                return;
            }
            this.quantity += amt;
            this.$emit('updateSkiprows', this.quantity-1);
        },
        resetCounter: function() {
            console.log("RESET COUNTER HAPPENED");
            this.quantity = 1;
            
        }

    }
}






</script>


<style>

[data-quantity] {
  position: absolute;
  right: 40px;
  width: 100%;
  max-width: 12rem;
  padding: 0;
  /* margin: 30px 0; */
  border: 0;
}

[data-quantity] legend {
  display: none;
}
[data-quantity] .skipcaption {
    font-size: small;
    text-align: center;
    padding-top: 5px;
    padding-right: 30px;
}

[data-quantity] input {
  font-size: 16px;
  height: 2rem;
  padding: 0 4rem;
  border-radius: 5rem;
  border: 0;
  background: #fff;
  color: #222;
  box-shadow: 0 10px 65px -10px rgba(0, 0, 0, 0.25);
  text-align: center;
  width: 80%;
  box-sizing: border-box;
  font-weight: lighter;
}

[data-quantity] input:focus {
  outline: none;
  box-shadow: 0 5px 55px -10px rgba(0, 0, 0, 0.2), 0 0 4px #3fb0ff; /* Allows border radius on focus */
}

[data-quantity] input[type=number]::-webkit-inner-spin-button,
[data-quantity] input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

[data-quantity] input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}
/* 
[data-quantity] button {
  position: absolute;
  width: 2.8rem;
  height: 2.8rem;
  top: 0.6rem;
  display: block;
  padding: 0;
  margin: 0;
  border: 0;
  background: #fff
    url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="50"><path d="M17 25h16M67 25h16M75 17v16" fill="none" stroke="rgb(59,69,66)" stroke-width="1.5px" stroke-linecap="round" stroke-linejoin="round" /></svg>')
    no-repeat 0 0;
  background-size: 5.6rem 2.8rem;
  overflow: hidden;
  white-space: nowrap;
  text-indent: 100%;
  border-radius: 1.4rem;
  cursor: pointer;
  transition: opacity 0.15s;
  opacity: 0.5;
} */

[data-quantity] button {
    position: absolute;
    width: 2rem;
    height: 2rem;
    top: 0rem;
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
    border: 0;
    background: #fff
    url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="50"><path d="M17 25h16M67 25h16M75 17v16" fill="none" stroke="rgb(59,69,66)" stroke-width="1.5px" stroke-linecap="round" stroke-linejoin="round" /></svg>')
    no-repeat 0 0;
    background-size: 4rem 2rem;
    overflow: hidden;
    white-space: nowrap;
    text-indent: 100%;
    border-radius: 2rem;
    cursor: pointer;
    transition: opacity 0.15s;
    opacity: 0.5;
}

[data-quantity] button:active {
  background-position-y: 1px;
  box-shadow: inset 0 2px 12px -4px #c5d1d9;
}

[data-quantity] button:focus {
  outline: none;
}

[data-quantity] button:hover {
  opacity: 1;
}

[data-quantity] button.sub {
  left: 1.5rem;
}

[data-quantity] button.add {
    right: 3.5rem;
    background-position-x: -2rem;
}

.skipcaption {
  position:absolute;
  right:15rem;
  top:0.3rem;
  
}
</style>