<template>
  <div class="chatbot-parent">

    <div class="query-header">
      <div class="circle" ref="attcircle" align-self="center"> 3  </div>
      <div class="query-heading">Chat with your document</div>
      
      <!-- <div class="underline"></div> -->
    </div>
    <div class="frame-parent2">

      <div id="chatbot">
        <div id="chat-container" ref="chatcontainer">
          <div v-for="(message, index) in messages" :key="index" :class="message.type">
            <br v-if="message.table != null && message.table.length > 0" />
            <vue-scrolling-table :deadAreaColor="'#f5f5f5'" v-if="message.table != null && message.table.length > 0">
              <template #thead>
                <tr>
                  <th v-for="(index, col) in message.table[0]" :key="index">
                    {{ col }}
                  </th>
                </tr>
              </template>
              <template #tbody>
                <tr v-for="(row, index) in message.table" :key="index">
                  <td v-for="(cell, index) in row" :key="index">{{ cell }}</td>
                </tr>
              </template>
            </vue-scrolling-table>
            <br />{{ message.text }}<br />
          </div>
          

        </div>
        <div v-if="messages.length != 0" class="clearchat"><img src="../assets/clearicon.png" v-on:click ="clearChat"></div>
      </div>
      <!-- <div class="search-prompt-parent13"> -->
      <div class="query-box-div">
        <div class="search-prompt-frame">
          <SearchInput ref="searchinput" :searchIcon=true :shortcut-icon=false id="searchinput" :disabled="totalrows==0" v-model="query" v-on:keyup.enter="enterQuery" v-on:focus="onFocusSearch"   v-on:blur="onBlurSearch" />
          
          
        </div>
        <VueSpinner color="gray" v-if="QInProgress" />
      </div>
      <!-- </div> -->

    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueScrollingTable from "vue-scrolling-table";
import "../../node_modules/vue-scrolling-table/dist/style.css";
import SearchInput from "vue-search-input";
import { VueSpinner } from "vue3-spinners";
import * as amplitude from '@amplitude/analytics-browser';

// import ChatBot from "./Chatbot.vue";

export default {
  name: "QueryComp",
  data() {
    return {
      query: "Upload sheet above",
      results: "results will show up here",
      QInProgress: false,
      datatable: [],
      userMessage: "",
      messages: [],
      scrollHappened:true,
      feedbackShown:false
    };
  },
  props: ["csvdata", "skiprows","totalrows"],
  components: {
    VueScrollingTable,
    SearchInput,
    VueSpinner,

  },
  updated() {

    var container = this.$el.querySelector(".search-prompt-frame");
    var topcontainer = this.$refs.chatcontainer;
    if (!this.scrollHappened && topcontainer.scrollHeight > window.innerHeight && this.messages.length > 1) {
    container.scrollIntoView({ block: 'start',  behavior: 'smooth' });
    this.scrollHappened=true;
    if ((!this.feedbackShown) && (this.messages.length > 10)) {
      window.location.hash = "#feedback-sheet";
      this.feedbackShown=true;
    }
  }
  },
  methods: {

    enterQuery: function (e) {
      this.$emit("querymodeEntered", true);
      console.log("do query is invoked");
      console.log(e.target.value);
      amplitude.track('Query entered',{"Query": e.target.value});
      this.queryAI(e.target.value);

    },
    clearChat: function() {
        this.messages=[];
    },
    onFocusSearch: function(){
      if ((this.totalrows == 0) || (this.query=="Type your message here")){
      this.query="";
      }
    },
    onBlurSearch: function(){
      
      if (this.totalrows == 0) {
      this.query="Upload sheet above";
      }
      else {
        if (this.query.length == 0) {
        this.query="Type your message here";
        }
      }
    
    },

    queryAI: async function (queryString) {
      // this.$el.querySelector("#chat-container").scrollIntoView();
      console.log("query AI is invoked");
      this.QInProgress = true;
      this.messages.push({ type: "user", text: queryString });
      let fdata = new FormData();
      const reader = new FileReader();

      reader.readAsDataURL(this.csvdata);

      reader.onload = () => {
        const base64String = reader.result.split(',')[1];
        // console.log(this.csvdata);
        fdata.append('filename', this.csvdata.name);
        fdata.append('csvfile', base64String);
        fdata.append('skiprows', this.skiprows);
        fdata.append("query", queryString);

        console.log(fdata);
        axios.post("https://1c736zt347.execute-api.us-east-1.amazonaws.com/dev/query", fdata).then(response => {
          // axios.post("http://localhost:8000/query", fdata).then(response => {
          console.log(response);
          try {
            var data = response.data.data.result;
          

          this.results = data["description"];
          this.datatable = data["table"];
          this.QInProgress = false;
          this.messages.push({
            type: "bot",
            text: this.results,
            table: data["table"],
          });
          this.query = "";
        } catch(error) {
            console.log("error in response");
            console.log(error)
            amplitude.track('Error in query response',{Query: fdata.get("query"),Error:error});
            data = { description: "Sorry.I cant find the that information", table: [] };
            this.results = data["description"];
          this.datatable = data["table"];
          this.QInProgress = false;
          
          this.messages.push({
            type: "bot",
            text: this.results,
            table: data["table"],
          });
          this.query = "";
          amplitude.track('Successful query response',{Query: fdata.get("query"),"Result":this.results,TableLength:this.datatable.length});

          }
        }).catch(error => {
          console.log(error);
          var data = { description: "Sorry.I cant find the that information", table: [] };
          amplitude.track('Error in query response',{Query: fdata.get("query"),"Error":String(error)});
          this.results = data["description"];
          this.datatable = data["table"];
          this.QInProgress = false;
          this.messages.push({
            type: "bot",
            text: this.results,
            table: data["table"],
          });
          this.query = "";
        })
      }
      reader.onerror = error => {
        console.log('An error occurred while reading the file', error);
        amplitude.track('Error in Data Reader',{Query: fdata.get("query"),Error:String(error)});
      }
      this.scrollHappened=false;
    }
  },
  watch: {
    totalrows: {
      handler(val) {
        console.log("total rows changed",val);
        this.query="Type your message here";
      }
    }
    }

}
</script>



<style>
.search-input-wrapper {
  position: relative;
  width: 100%;
}

.search-input-wrapper input[data-search-input="true"] {
  display: block;
  font-family: "Inter", system-ui, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  width: 100%;
  padding: 6px 30px 6px 32px;
  font-size: 16px;
  font-weight: normal;
  height: 38px;
  color: #333;
  background-color: #f6f9fc;
  border: 1px solid #f6f9fc;
  border-radius: 0.35rem;
  transition: border-color 0.15s ease-in-out;
}

.search-input-wrapper input[data-search-input="true"]:focus {
  background-color: white;
  border-color: #1ea7fd;
  outline: 0;
  box-shadow: none;
}

.search-input-wrapper input[data-search-input="true"]:not(:focus) {
 color:#b0aeae;
}

.search-input-wrapper .search-icon {
  color: #83add6;
  position: absolute;
}

.search-input-wrapper .search-icon.search {
  left: 12px;
  bottom: 12px;
  box-sizing: border-box;
  background-color: #1ea7fd;
  display: block;
  width: 20px;
  height: 14px;
  border: 2px solid;
  border-radius: 100%;
  margin-left: -4px;
  margin-top: -4px;
}


.search-input-wrapper .search-icon.search::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 10px;
  width: 3px;
  height: 10px;
  background: #83add6;
  transform: rotate(-55deg);
  top: 8px;
  left: 10px;
}

.search-input-wrapper .search-icon.shortcut {
  width: 22px;
  height: 24px;
  cursor: text;
  right: 8px;
  bottom: 7px;
  background-color: #e7eff7;
  border-radius: 3px;
  z-index: 6;
}

.search-input-wrapper .search-icon.shortcut::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 2px;
  transform: rotate(25deg);
  width: 2px;
  height: 16px;
  top: 4px;
  left: 10px;
  z-index: 7;
  background-color: #96b9dc;
}

.search-input-wrapper .search-icon.clear {
  right: 5px;
  bottom: 7px;
  cursor: pointer;
  /* z-index: 5; */
  box-sizing: border-box;
  display: block;
  width: 24px;
  height: 24px;
  border: 2px solid transparent;
  border-radius: 40px;
  background: none;
  padding: 0px;
  outline: none;
}

.search-input-wrapper .search-icon.clear:focus {
  background: #e7eff7;
  color:#b0aeae;
}

.search-input-wrapper .search-icon.clear::after,
.search-input-wrapper .search-icon.clear::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 16px;
  height: 2px;
  background: #83add6;
  transform: rotate(45deg);
  border-radius: 5px;
  top: 9px;
  left: 2px;
}

.search-input-wrapper .search-icon.clear::after {
  transform: rotate(-45deg);
}

/* Fix the X appearing in search field on Chrome and IE */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

#chatbot {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 75%;
  overflow: scroll;
  border: 2px;
}

#chat-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scroll-behavior: smooth;
  flex-grow: 1;
  padding: 1rem;
}

input {
  padding: 1rem;
}

.user {
  text-align: right;
  align-self: flex-end;
  background-color: #2979ff;
  color: white;
  padding: 1rem;
  border-radius: 20px;
  margin-bottom: 1rem;
  max-width: 70%;
  word-wrap: normal;
}

.bot {
  display: flex;
  flex-direction: column;
  text-align: left;
  align-self: flex-start;
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 20px;
  margin-bottom: 1rem;
  max-width: 95%;

}
</style>