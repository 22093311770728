<template>
  <div class="datacontainer-div" ref="datacontainerdiv">
    <div class="datalabel-container-div" v-on:click = "toggleTable"  >
      
      <div class="circle" ref="reviewcircle" align-self="center"> 2  </div>
     <div v-if="csvdata.name != null" class="datalabel-div">Review &nbsp; <i> {{ humanizeFilename(csvdata.name) }} &nbsp;  </i>
      <img v-if="!showTable" class="disclosure-img"  :src="require(`@/assets/discclose.png`)"/>
      <img v-if="showTable" class="disclosure-img" :src="require(`@/assets/discopen.png`)"/> </div>

      <div v-else class="datalabel-div">Review &nbsp; 
        <img v-if="!showTable" class="disclosure-img"  :src="require(`@/assets/discclose.png`)"/>
      <img v-if="showTable" class="disclosure-img" :src="require(`@/assets/discopen.png`)"/>
    </div>
    </div>
    
    <div class="datatable-container-div" v-show="showTable">

      <div v-if="csvdata.name != null" class="spinner-div">

        <incrementSpinner ref="spinner"  
          @updateSkiprows="onUpdateSkiprows" />
      </div>
      <div class="linedivider-div"></div>

      <vue-scrolling-table :deadAreaColor="'white'" v-if="tableData.length != 0">
        <template #thead>
          <tr>
            <th v-for="(index, col) in tableData[0]" :key="index"> {{ col }} </th>
          </tr>
        </template>
        <template #tbody>
          <tr v-for="(row, index) in tableData" :key="index">
            <td v-for="(cell, index) in row" :key="index"> {{ cell }} </td>

          </tr>
        </template>
      </vue-scrolling-table>
      <div v-else class="nodatamsg">
        <p> {{ message }} </p>
        <vue-spinner color="Gray" v-if="inprogress" style="align-items:center;justify-content: center;" />

      </div>

    </div>


  </div>
</template>
<script>
import axios from 'axios';
import VueScrollingTable from "vue-scrolling-table"
import "../../node_modules/vue-scrolling-table/dist/style.css"
import { VueSpinner } from "vue3-spinners";
import incrementSpinner from './incrementSpinner.vue'
import * as amplitude from '@amplitude/analytics-browser';


export default {

  name: 'DataComp',
  props: ['csvdata'],
  data() {
    return {
      tableData: [],
      inprogress: false,
      message: "Your data shows up here",
      skiprows: 0,
      showTable: true
    }
  },
  components: {
    VueScrollingTable,
    VueSpinner, incrementSpinner
  },
  emits: ['updateSkiprows','updateTotalrows'],
  methods: {
    humanizeFilename(str) {
      var cameltoString = str.replace(/([A-Z])/g, function (match) {
        return ' ' + match.toLowerCase();
      });

      var underscore_removed = cameltoString.replace(/_/g, ' ').split('.')[0];
      return underscore_removed.replace(/\w\S*/g, function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })

    },
    toggleTable(){
      if (this.showTable) {
        this.showTable = false;
        this.$refs.datacontainerdiv.style="height: 3rem;min-height:3rem";
        if (this.tableData.length > 0 ){
          this.$refs.reviewcircle.style="background-color: var(--color-numbercircle-fill)";}
      }
      else {
      this.showTable = true;
      this.$refs.datacontainerdiv.style="height: 35%;min-height:55vh;"
      }
    },
    async updateTableData(csvfile, skiprows) {
      let fdata = new FormData();
      console.log("in data vue update table data");
      const reader = new FileReader();

      reader.readAsDataURL(csvfile);

      reader.onload = () => {
        console.log(csvfile);
        const base64String = reader.result.split(',')[1];
        fdata.append('filename', csvfile.name)
        fdata.append('csvfile', base64String);
        fdata.append('skiprows', skiprows);
        console.log(fdata);
        this.message = "Processing your file...";
        this.tableData = [];
        this.inprogress = true;

        axios.post('https://1c736zt347.execute-api.us-east-1.amazonaws.com/dev/dataset', fdata).then(response => {

          // axios.post('http://localhost:8000/dataset', fdata).then(response => {
          console.log(response.data);
          if (response.data.success) {
            var data = response.data.data.result;
            this.tableData = data;
            this.inprogress = false;
            this.$emit('updateTotalrows', this.tableData.length);
            amplitude.track('Dataset Fetch Success',{tablesize: this.tableData.length});
          }
          else {
            this.message = "Your file could not be read. Please check the file format and try again.";
            this.inprogress = false;
            this.tableData = [];
            amplitude.track('Dataset Fetch Failed with no data');
          }

        })
          .catch(error => {
            console.log(error);
            this.inprogress = false;
            this.message = "Your file could not be processed at the moment. Please try again later.";
            this.tableData = [];
            amplitude.track('Dataset Fetch Failed with no data',{Error:String(error)});
          })
      }

      reader.onerror = error => {
        amplitude.track('Error in Data Reader',{"Query": fdata.get("query"),Error:String(error)});
        console.log('An error occurred while reading the file', error);
      }

    },

    onUpdateSkiprows(skiprows) {
      this.skiprows = skiprows;
      this.$emit('updateSkiprows', skiprows);
      this.updateTableData(this.csvdata, this.skiprows);
    }
    
  },
  watch: {
    csvdata: {
      async handler(val) {
        try {
          await this.$refs.spinner.resetCounter();
        }
        catch (error) {
          console.log(error);
        }
        this.skiprows = 0;
        if (!this.showTable) {
        this.toggleTable();
        }
        this.updateTableData(val, this.skiprows);

      }

    }
  },




}



</script>

<style>
.nodatamsg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 20px;
  font-weight: 500;
  color: #5d7789;
}

table.scrolling {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  overflow: hidden;
  --dead-area-color: var(--5ba96d6b);
  background-color: var(--dead-area-color)
}

table.scrolling thead,
table.scrolling tfoot {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  display: block;
  overflow: hidden
}

table.scrolling tbody {
  display: block;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  overflow: hidden
}

table.scrolling.scrollx tbody {
  overflow-x: scroll
}

table.scrolling.scrolly tbody,
table.scrolling.scrolly thead.scrollsync,
table.scrolling.scrolly tfoot.scrollsync {
  overflow-y: scroll
}

.scroll-vertical.scrollsync {
  background-color: var(--dead-area-color);
  scrollbar-base-color: var(--dead-area-color);
  scrollbar-face-color: var(--dead-area-color);
  scrollbar-highlight-color: var(--dead-area-color);
  scrollbar-track-color: var(--dead-area-color);
  scrollbar-arrow-color: var(--dead-area-color);
  scrollbar-shadow-color: var(--dead-area-color);
  scrollbar-darkshadow-color: var(--dead-area-color)
}

table.scrolling.scrolly thead.scrollsync::-webkit-scrollbar {
  display: block;
  background-color: var(--dead-area-color)
}

table.scrolling.scrolly thead.scrollsync::-webkit-scrollbar-track {
  background-color: var(--dead-area-color)
}

table.scrolling td,
table.scrolling th {
  width: 10em;
  min-width: 10em;
  max-width: 10em;
  overflow: hidden;
  word-wrap: break-word
}

:where(table.scrolling td) {
  background-color: #fff;
  border: 1px solid #ddd
}

:where(table.scrolling th) {
  background-color: #f7f7f7;
  border: 1px solid #ddd
}
</style>