<template>
  <div class="footer-div" id="footer">
        <div class="frame-group">
            <div v-on:click="onclick('Home')" class="h2-heading"><a href="https://www.acuit.ai">Home</a></div>
          <div v-on:click="onclick('Contact')"  class="h2-heading"><a href="mailto:contact@acuit.ai">Contact Us</a></div>
          <div  v-on:click="onclick('Privacy')" class="h2-heading"><a href="https://www.acuit.ai/privacy.html">Privacy</a></div>
          <div  v-on:click="onclick('TOS')" class="h2-heading"><a href="https://www.acuit.ai/tos.html">Terms</a></div>
          <div  v-on:click="onclick('Share')" > <a href="#bottom-sheet"> <img class="footer-image" src="../assets/shareicon.png"> </a></div>
          <div  v-on:click="onclick('Feedback')" > <a href="#feedback-sheet"> <img class="footer-image" src="../assets/infoicon.png"> </a></div>
          <ShareComp></ShareComp>
          <FeedbackComp></FeedbackComp>
        </div>
      </div>
</template>

<script>  
import ShareComp from './ShareSheet.vue'
import FeedbackComp from './Feedback.vue'
import * as amplitude from '@amplitude/analytics-browser';
export default {
name: "FooterComp",
methods:{
  
    onclick(link) {
        console.log("CLICKED");
        amplitude.track('Footer link clicked',{FooterLink:link});
        
    }
},
components: {
    ShareComp,
    FeedbackComp
}
}
</script>
