<template>

<div class="attachment-div" ref="attdiv">
        <div class="upload-parentbox-div">
           <div class="upload-container-div" v-if="dragAreaVisible">
            
            <div v-if="!urlMode" class="upload-parent"  v-bind="getRootProps()">
              <input v-bind="getInputProps()" accept=".csv,.xls,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,application/json,application/xml" />
              <img class="upload-icon1" alt="" src="../assets/upload1.svg">

              <div class="upload-prompt">
                <p v-if="isDragActive">Drop the files (excel,csv,xml,json) here ...</p>
                <p v-else>Drag 'n' drop some files (excel,csv,xml,json)  here, or click to select files</p>
              </div>
              <button type="image" class="upload-btn"  >
                <img class="ic24-upload-icon" alt="" src="../assets/ic24fileadd.svg">   
                <div class="upload-document">Upload File</div>
                
               </button>  
              
            </div>
            <div  class="upload-parent"  v-if="dragAreaVisible && urlMode">
              <input ref="urlInput" class="url-input" v-model="htmlTableURL"  v-on:focus="onURLFocus" @keyup.enter="submitURL"/>

              <div class="upload-prompt">
                <p> Enter the url of the website to extract table data. </p>
                <p> Google sheets share option should be set to  "anyone with the link".  </p>
              </div>
              <button type="image" v-on:click="submitURL" class="upload-btn"  >
                <div class="upload-document">Connect to Site</div>
                
               </button>  
              
            </div>
            <div class="url-link"  v-on:click="showURLSheet"><a> {{urlMode  ? 'Upload a Spreadsheet instead' : 'Link a website  instead' }} </a>  </div>

          </div>
          
        </div>
        <div class="upload-header"  @click = "onDisclosure">
          <div class="circle" ref="attcircle" align-self="center"> 1  </div>

          <div class="h2-heading">  &nbsp; {{ urlMode ? 'Connect to URL' :  'Upload Document' }} &nbsp; </div>
          <img v-if="dragAreaVisible" class="disclosure-img"  :src="require(`@/assets/discopen.png`)"/>
          <img v-else class="disclosure-img" :src="require(`@/assets/discclose.png`)"/>
        </div>
      </div>




</template>

<script>
import { useDropzone } from "vue3-dropzone";
import { ref } from 'vue';
import * as amplitude from '@amplitude/analytics-browser';


export default {
  name: "AttachmentComponent",
  setup(props,context) {
    
    const files = ref([]);
    const attdiv = ref(null);
    const attcircle=ref(null);
    const urlMode=ref(false);
    const  urlInput = ref(null)
    const htmlTableURL = ref("https://docs.google.com/spreadsheets/d/.......");
    var dragAreaVisible = ref(true);
    const acceptedFiles = '.webloc,.plist,.csv,.xls,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,application/json,application/xml,MACS/ilht';
    function onDrop(acceptFiles, rejectReasons) {
      console.log(rejectReasons);
      context.emit('datauploaded',acceptFiles[0]);  
      dragAreaVisible.value=false;
      attdiv.value.style="height:3rem;min-height:3rem";
      attcircle.value.style="background-color: var(--color-numbercircle-fill)";


      
      
    }

    function onDisclosure() {
      dragAreaVisible.value=!dragAreaVisible.value;
      console.log(dragAreaVisible);
      // console.log(discimgref.src);
      console.log(dragAreaVisible.value);
      if(dragAreaVisible.value){
        attdiv.value.style="height:30%";
      }
      else {
        attdiv.value.style="height:3rem;min-height:3rem";
      }
    }

    function onURLFocus() {
      htmlTableURL.value="";
      console.log(urlInput.value.style)
      urlInput.value.style='color:blue';
    }

    function submitURL() {
      console.log(htmlTableURL.value);
      var filecontent='<plist version="1.0"><dict><key>URL</key><string>%%%URL%%%</string></dict></plist>'.replace("%%%URL%%%",htmlTableURL.value)
      console.log(filecontent)
      var blob = new Blob([filecontent], { type: 'text/plain' });
      var file = new File([blob], "url.webloc", {type: "text/plain"});
      dragAreaVisible.value=false;
      attdiv.value.style="height:3rem;min-height:3rem";
      attcircle.value.style="background-color: var(--color-numbercircle-fill)";
      context.emit('datauploaded',file);
      amplitude.track('Website Link Entered',{url: htmlTableURL.value});
    }

    function showURLSheet(){
      console.log("emitting urlmode")
      if (urlMode.value) {
        urlMode.value=false;
      }
      else {
        urlMode.value=true;
      }
      amplitude.track('Website mode selected');
    }

    const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop ,acceptedFiles});

    return {
      files,
      dragAreaVisible,
      attdiv,
      attcircle,
      urlMode,
      urlInput,
      htmlTableURL,
      submitURL,
      onDisclosure,
      showURLSheet,
      onURLFocus,
      getRootProps,
      getInputProps,
      ...rest,
    };
  },
};
</script>

<style>
.url-input{
  font-size:medium;
  color:rgb(191, 207, 207);
  border-radius: 50px;
  width:60%;
  align-self: center;  
}

</style>
