<template>
    <div id="feedback-sheet" tabindex="-1" class="overlay">
			<aside class="feedback" role="dialog" aria-labelledby="modal-label" aria-hidden="true">
                    <div class="wrap">
                        <div class = "header">
                        <h3 id="modal-label">How was your experience?</h3>
                        <p>Help us improve our product by providing feedback.</p>
                        </div>
						<ul class="menu">
									<li><div v-on:click = "onclick('happy')" class="emojicontainer"> <img v-if="(feedback == 'happy' ) || (feedback == 'none' )"  class="emojiimg" src="../assets/happy.png"> <img v-else class="emojiimg" src="../assets/happy_disabled.png">  </div> </li>
									<li><div v-on:click = "onclick('neutral')" class="emojicontainer"> <img v-if="(feedback == 'neutral' ) || (feedback == 'none' )"  class="emojiimg" src="../assets/neutral.png"> <img v-else class="emojiimg" src="../assets/neutral_disabled.png">  </div> </li>
									<li><div v-on:click = "onclick('sad')" class="emojicontainer"> <img v-if="(feedback == 'sad' ) || (feedback == 'none' )"  class="emojiimg" src="../assets/sad.png"> <img v-else class="emojiimg" src="../assets/sad_disabled.png">  </div> </li>
						</ul>
                    </div>
			</aside>
	<a href="#footer" v-on:click ="closefeedback" class="btn-close" aria-hidden="true"><span class="mdi mdi-close"></span><span class="sr-only">Close</span></a>
</div>
</template>
<script>
import * as amplitude from '@amplitude/analytics-browser';


export default {

name: 'FeedbackComp',
methods:{
    onclick(emotion) {
        this.feedback = emotion;
        amplitude.track('Feedback changed',{"feedback":this.feedback});
    },
    closefeedback() {
        console.log("closed feedback")
        amplitude.track('Feedback submitted',{"feedback":this.feedback});
    }
},
data() {
    return {
        feedback:"none",
    }
}
}


</script>


<style scoped>
@import 'https://cdn.materialdesignicons.com/1.3.41/css/materialdesignicons.min.css';
img {
  max-width: 100%;
  height: auto;
}

header {
  padding: 24px;
  background-color: #009688;
}

.wrap {
  display: flex;
  flex-direction: column;
}

.emojicontainer
{
    display:flex;
    flex-direction: column;
    justify-content: center;
}

.emojiimg
{
    height:128px;
    width: 128px;
    min-width: 64px;
    min-height:64px;
    object-fit:contain;
}

.tickimg
{
    display:block;
    height: 128px;
    width: 128px;
    object-fit: contain ;
    min-width: 64px;
    min-height:64px;
}

ul.menu {
    list-style: none;
    margin-left: 30px;
    width:90%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 10px;
    
}

.overlay {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  opacity: 0;
  transition: all 450ms cubic-bezier(0.32, 1, 0.23, 1) 0ms;
}
.overlay .feedback {
  position: fixed;
  bottom: 0;
  width: 100%;
  height:25%;
  background-color: #fff;
  padding: 8px 24px 16px;
  box-sizing: border-box;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.22), 0px 14px 56px rgba(0, 0, 0, 0.25);
  transform: translate(0, 100%);
  transition: all 450ms cubic-bezier(0.32, 1, 0.23, 1) 100ms;
}
.overlay .feedback ul {
  text-align: justify;
  -moz-columns: 74px 3;
       columns: 74px 3;
  vertical-align: bottom;
}
.overlay .feedback ul li {
  width: 74px;
  display: inline-block;
}
.overlay .feedback ul a {
  display: block;
  margin: 8px 0;
  color: gray;
  text-decoration: none;
  text-align: center;
}
.overlay .feedback ul a span {
  display: inline-block;
  width: 100%;
}
.overlay .feedback .facebook span {
  color: #3b5998;
}
.overlay .feedback .twitter span {
  color: #00aced;
}
.overlay .feedback .linkedin span {
  color: #007bb5;
}
.overlay .feedback .reddit span {
  display: block;
  width: 48px;
  height: 48px;
  margin: 0 auto;
  border-radius: 2px;
  background-color: #cee3f8;
  color: #fff;
  text-shadow: 1px 1px #000, -1px -1px #000, -1px 1px #000, 1px -1px #000;
}
.overlay .feedback .reddit span::after {
  content: "";
  display: block;
  height: 4px;
  width: 4px;
  background-color: red;
  border-radius: 50%;
  position: relative;
  top: -24px;
  left: 16px;
  box-shadow: 12px 0 red;
}
.overlay .feedback .whatsapp span {
  color: #4dc247;
}
.overlay .btn-close {
  color: #666;
  transform: scale(0, 0);
  transition: all 450ms ease-in-out 0;
}
.overlay:target {
  display: block;
  position: fixed;
  top: 0;
  opacity: 1;
}
.overlay:target .feedback {
  transform: translate(0, 0);
  z-index: 9;
}
.overlay:target .btn-close {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  outline: 0 none;
  transform: scale(1, 1);
}

@media screen and (min-width: 640px) {
  .overlay {
    padding: 0 24px;
  }
  .overlay .feedback {
    width: calc(100% - 48px);
  }
  .overlay .feedback ul {
    -moz-column-gap: 16px;
         column-gap: 16px;
  }
}
.mdi::before {
  font-size: 24px;
  line-height: 48px;
}

.feedback .mdi::before {
  font-size: 48px;
}

.sr-only {
  /* a generic way to visually hide content while remaining accessible to screen readers (h5bp.com) */
  clip: rect(0 0 0 0);
  overflow: hidden;
  position: absolute;
  height: 1px;
  width: 1px;
}

</style>