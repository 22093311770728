<template>
  <div class="header-div">
        <div class="acuitlogo-1-parent">
          <img class="acuitlogo-1-icon" alt="" src="../assets/acuitlogo-1@2x.png">
          <div class="brand-h1"> SheetTalk </div>
        </div>
        <div class="heading-h1"> Chat with Your Spreadsheet </div>

      </div>
</template>

<script>
export default {
name: 'HeaderComp'
}
</script>

<style>

</style>