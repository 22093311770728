<template>
    <div id="bottom-sheet" tabindex="-1" class="overlay">
			<aside class="social" role="dialog" aria-labelledby="modal-label" aria-hidden="true">
						<ul class="menu">
									<li><a class="facebook" href="https://www.facebook.com/sharer/sharer.php?=https%3A//sheettalk.acuit.ai/" target="_blank" title="Share on Facebook"><span class="mdi mdi-facebook-box" aria-hidden="true" aria-role="presentation"></span> Facebook</a></li>
									<li><a class="twitter" href="https://twitter.com/home?status=https%3A//sheettalk.acuit.ai/" target="_blank" title="Tweet this page"><span class="mdi mdi-twitter" aria-hidden="true" aria-role="presentation"></span> Twitter</a></li>
									<li><a class="linkedin" href="https://www.linkedin.com/shareArticle?mini=true&url=https%3A//twitter.com/home?status=https%3A//sheettalk.acuit.ai/&title=Chatbot$20for%20your%20Spreadsheets" target="_blank" title="Share on LinkedIn"><span class="mdi mdi-linkedin" aria-hidden="true" aria-role="presentation"></span>LinkedIn</a></li>
									<li><a class="reddit" href="//www.reddit.com/submit?url=https%3A//sheettalk.acuit.ai/" target="_blank" title="Share on Reddit"><span class="mdi mdi-reddit" aria-hidden="true" aria-role="presentation"></span> Reddit</a></li>
									<li><a class="whatsapp" href="whatsapp://send?text=https%3A//sheettalk.acuit.ai" data-action="share/whatsapp/share" target="_blank" title="Send to WhatsApp"><span class="mdi mdi-whatsapp" aria-hidden="true" aria-role="presentation"></span> WhatsApp</a></li>
									<li><a class="email" href="mailto:?subject=Found a new spreadsheet chatbot&amp;body=Chatbot for your spreadshet: https%3A//sheettalk.acuit.ai"><span class="mdi mdi-email" aria-hidden="true" aria-role="presentation"></span> E-mail</a></li>
						</ul>
			</aside>
	<a href="#footer" class="btn-close" aria-hidden="true"><span class="mdi mdi-close"></span><span class="sr-only">Close</span></a>
</div>
</template>
<script>


export default {

name: 'ShareComp',
methods:{
    onclick() {
        window.location.hash = "#bottom-sheet";
        this.count++;
    }
},
data() {
    return {
        count:0,
    }
}
}


</script>

<style scoped>
@import 'https://cdn.materialdesignicons.com/1.3.41/css/materialdesignicons.min.css';
img {
  max-width: 100%;
  height: auto;
}

header {
  padding: 24px;
  background-color: #009688;
}

.wrap {
  max-width: 100%;
  margin: 0 auto;
}

ul.menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.overlay {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  opacity: 0;
  transition: all 450ms cubic-bezier(0.32, 1, 0.23, 1) 0ms;
}
.overlay .social {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  padding: 8px 24px 16px;
  box-sizing: border-box;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.22), 0px 14px 56px rgba(0, 0, 0, 0.25);
  transform: translate(0, 100%);
  transition: all 450ms cubic-bezier(0.32, 1, 0.23, 1) 100ms;
}
.overlay .social ul {
  text-align: justify;
  -moz-columns: 74px 3;
       columns: 74px 3;
  vertical-align: bottom;
}
.overlay .social ul li {
  width: 74px;
  display: inline-block;
}
.overlay .social ul a {
  display: block;
  margin: 8px 0;
  color: gray;
  text-decoration: none;
  text-align: center;
}
.overlay .social ul a span {
  display: inline-block;
  width: 100%;
}
.overlay .social .facebook span {
  color: #3b5998;
}
.overlay .social .twitter span {
  color: #00aced;
}
.overlay .social .linkedin span {
  color: #007bb5;
}
.overlay .social .reddit span {
  display: block;
  width: 48px;
  height: 48px;
  margin: 0 auto;
  border-radius: 2px;
  background-color: #cee3f8;
  color: #fff;
  text-shadow: 1px 1px #000, -1px -1px #000, -1px 1px #000, 1px -1px #000;
}
.overlay .social .reddit span::after {
  content: "";
  display: block;
  height: 4px;
  width: 4px;
  background-color: red;
  border-radius: 50%;
  position: relative;
  top: -24px;
  left: 16px;
  box-shadow: 12px 0 red;
}
.overlay .social .whatsapp span {
  color: #4dc247;
}
.overlay .btn-close {
  color: #666;
  transform: scale(0, 0);
  transition: all 450ms ease-in-out 0;
}
.overlay:target {
  display: block;
  position: fixed;
  top: 0;
  opacity: 1;
}
.overlay:target .social {
  transform: translate(0, 0);
  z-index: 9;
}
.overlay:target .btn-close {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  outline: 0 none;
  transform: scale(1, 1);
}

@media screen and (min-width: 640px) {
  .overlay {
    padding: 0 24px;
  }
  .overlay .social {
    width: calc(100% - 48px);
  }
  .overlay .social ul {
    -moz-column-gap: 64px;
         column-gap: 64px;
  }
}
.mdi::before {
  font-size: 24px;
  line-height: 48px;
}

.social .mdi::before {
  font-size: 48px;
}

.sr-only {
  /* a generic way to visually hide content while remaining accessible to screen readers (h5bp.com) */
  clip: rect(0 0 0 0);
  overflow: hidden;
  position: absolute;
  height: 1px;
  width: 1px;
}

</style>