<template>
  <HeaderComp />

  <div class="container-main">
    <AttachmentComponent @datauploaded="datauploaded" />
    <DataComp :csvdata="csvdata" :totalrows="totalrows" @updateSkiprows="onUpdateSkiprows" @updateTotalrows="onUpdateTotalrows" />
    <QueryComp ref="querycomp" :csvdata="csvdata" :skiprows="skiprows"  :totalrows="totalrows" @querymodeEntered="querymodeEntered" />
    
  </div>


  <FooterComp />
</template>

<script>
import AttachmentComponent from './components/Attachment.vue'
import HeaderComp from './components/Header.vue'
import FooterComp from './components/Footer.vue'
import DataComp from './components/Data.vue'
import QueryComp from './components/Query.vue'
import * as amplitude from '@amplitude/analytics-browser';


export default {
  name: 'App',
  data() {
    return {
      csvdata: "Data",
      skiprows: 0,
      totalrows:0,
      querymode: false
    }
  },
  components: {
    HeaderComp,
    AttachmentComponent,
    DataComp,
    QueryComp,
    FooterComp
},
  methods: {
    datauploaded(data) {
      console.log(data.name);
      
      amplitude.track('File Uploaded',{Filename: data.name,FileExtension: String((data.name)).split(".")[1]});
      this.csvdata = data;
      this.$refs.querycomp.clearChat()
    },
    querymodeEntered(querymode){
      console.log("query mode entered");
      amplitude.track('Query mode Entered',{Querymode: querymode});
      console.log(querymode);
      // this.querymode = querymode;
      // document.querySelector(".right").style.width = "100%";
    },
    onUpdateSkiprows(skiprows) {
      console.log("in App vue skiprows update");
      amplitude.track('Skip rows updated',{SkipRows: skiprows});
      this.skiprows = skiprows;
    },
    onUpdateTotalrows(totalrows) {
      console.log("in App vue totalrows update");
      amplitude.track('Total rows updated',{TotalRows: totalrows});
      this.totalrows=totalrows;
      console.log(this.totalrows);
    },
    expand(){
      this.querymode = false;
    }
  },
  mounted(){
    amplitude.init('c64ef8bc181f02c274222d1fc4243907');
    amplitude.track('Session started');
    console.log("session started");
  }
}
</script>

<style>
#app {
  /* fonts */
  --font-inter: Inter;

  /* font sizes */
  --font-size-xl: 20px;
  --font-size-mini: 15px;
  --font-size-base: 16px;

  /* Colors */
  --color-whitesmoke-100: #f7f7f7;
  --color-whitesmoke-200: #f2f2f2;
  --color-whitesmoke-300: #efefef;
  --color-white: #fff;
  --color-gray: #101010;
  --color-mediumblue: #403cff;
  --grey: #5d7789;
  --color-gainsboro: #d9d9d9;
  --blue: #487bff;
  --color-numbercircle-fill: rgb(164, 255, 181);

  /* Gaps */
  --gap-base: 16px;
  --gap-3xs: 10px;
  --gap-252xl: 271px;
  --gap-274xl: 293px;
  --gap-18xl: 37px;
  --gap-9xs: 4px;

  /* Paddings */
  --padding-4xl: 23px;
  --padding-3xl: 22px;
  --padding-sm: 14px;
  --padding-19xl: 38px;

  /* border radiuses */
  --br-xl: 20px;
  --br-5xs: 8px;
  --br-42xl: 61px;
  --br-11xs: 2px;
  margin-top: 0px;
}
</style>
